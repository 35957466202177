<template>
  <div class="exReport_detail_container">
    <div class="header">
      <van-icon name="orders-o" />
      <div class="info">
        <div>{{ query.groupName }}</div>
        <div>
          <span>检查医生：</span>
          <span style="color:gray">{{ exReportDetail.reportDoctor }}</span>
        </div>
      </div>
    </div>
    <div class="total" style="color:#d2b330;white-space: pre-line;">
      <span style="color:#d2b330">初步意见：{{ exReportDetail.suggest == '' ? '暂无' : exReportDetail.suggest }}</span>
    </div>
    <!-- 普通检查 -->
    <div v-if="query.groupType === '0'">
      <div class="routine_list" v-for="(item, index) in exReportDetail.itemList" :key="index">
        <div class="item">{{ item.itemName }}：{{ item.result }} {{ item.unit }}</div>
      </div>
    </div>
    <!-- 影像检查 -->
    <div v-if="query.groupType === '1'">
      <div class="organ_list" v-for="(item, index) in exReportDetail.itemList" :key="index">
        <div style="white-space: pre-line;">{{ item.itemName }}：{{ item.result }}</div>
      </div>
    </div>
    <!-- 检验检查 -->
    <div class="checked_list" v-if="query.groupType === '2'">
      <van-row class="thead">
        <van-col span="5">项目名称</van-col>
        <van-col span="5">项目结果</van-col>
        <van-col span="4">单位</van-col>
        <van-col span="5">参考下限</van-col>
        <van-col span="5">参考上限</van-col>
      </van-row>
      <van-row class="tr" v-for="(item, index) in exReportDetail.itemList" :key="index">
        <div :class="item.resultTip === '' ? '' : 'anomalous'">
          <van-col span="5">{{ item.itemName === '' ? '-' : item.itemName }}</van-col>
          <van-col span="5">{{ item.result === '' ? '-' : item.result }} {{ item.resultTip }}</van-col>
          <van-col span="4">{{ item.unit === '' ? '-' : item.unit }}</van-col>
          <van-col span="5">{{ item.lowerValue === '' ? '-' : item.lowerValue }}</van-col>
          <van-col span="5">{{ item.upperValue === '' ? '-' : item.upperValue }}</van-col>
        </div>
      </van-row>
    </div>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Detail",
  data () {
    return {
      query: {},
      exReportDetail: {}
    };
  },
  created () {
    this.query = this.$route.query;
    this.peReportResultDetail();
  },
  methods: {
    //获取体检报告详情
    async peReportResultDetail () {
      this.$toast.loading({
        duration: 0,
        message: "加载中",
        forbidClick: true
      });
      const { data: res } = await jsonPost("/wxapp/pe/peReportResultDetail", {
        reportNo: this.query.reportNo,
        groupCode: this.query.groupCode
      });
      this.$toast.clear();
      if (res.code === 0) {
        console.log(111, res);
        if (this.query.groupType === "0") {
          this.exReportDetail = res.data;
        } else if (this.query.groupType === "1") {
          this.exReportDetail = res.data;
          // for (let i = 0; i < this.exReportDetail.itemList.length; i++) {
          //   this.exReportDetail.itemList[
          //     i
          //   ].result = this.exReportDetail.itemList[i].result.replace(
          //     /\\n/g,
          //     "\n"
          //   );
          // }
        } else if (this.query.groupType === "2") {
          this.exReportDetail = res.data;
        }
        this.exReportDetail.suggest = this.exReportDetail.suggest.replace(
          /\\n/g,
          "\n"
        );
      } else {
        this.$toast.fail(res.msg);
      }
    }
  }
};
</script>
<style scope>
.exReport_detail_container {
  padding-bottom: 60px;
}

.exReport_detail_container .header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 1;
  background-color: white;
  display: flex;
  padding: 20px 30px;
}

.exReport_detail_container .header .van-icon {
  font-size: 150px;
  color: #1989fa;
}

.exReport_detail_container .header .info {
  font-size: 40px;
  margin-left: 20px;
}

.exReport_detail_container .header .info div {
  margin-top: 16px;
}

.exReport_detail_container .total {
  padding: 20px 30px;
  background-color: #f9fceb;
  font-size: 40px;
  line-height: 80px;
  color: #d2b330;
}

.exReport_detail_container .routine_list {
  padding: 0 30px;
  color: gray;
  font-size: 40px;
}

.exReport_detail_container .routine_list .item {
  border-bottom: 1px solid #eee;
  height: 100px;
  line-height: 100px;
}

.exReport_detail_container .organ_list {
  padding: 20px 30px;
  color: gray;
  font-size: 40px;
  line-height: 80px;
  border-bottom: 1px solid #eee;
}

.exReport_detail_container .checked_list {
  text-align: center;
  font-size: 34px;
}

.exReport_detail_container .checked_list .thead {
  padding: 20px 10px;
  border-bottom: 1px solid #eee;
}

.exReport_detail_container .checked_list .tr {
  padding: 20px 10px;
  border-bottom: 1px solid #eee;
}

.exReport_detail_container .checked_list .tr .anomalous {
  color: red;
}
</style>